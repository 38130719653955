:root {
  --primary-color: #ff4a01;
  --secondary-color: #1b1f6b;
  --background-color: #f2efee;
  --main-title-font: "Verdana", sans-serif;
  --logo-src-url: "https://www.logo.nl/logo.svg";
}

* {
  box-sizing: border-box;
}

a {
  color: var(--primary-color);
  text-decoration: none;
}

html, body {
  color: #333;
  background-color: var(--background-color);
  margin: 0;
  padding: 0;
  font-family: Merriweather, serif;
  font-size: 14px;
  line-height: 1.6;
}

header {
  text-align: center;
  max-width: 320px;
  margin: 35px auto 30px;
  overflow: hidden;
}

#recaptcha-container {
  margin-top: 20px;
}

#finders-message {
  background-color: #f2efee;
  padding: 20px;
  font-style: italic;
  display: none;
}

input, textarea, select {
  outline: none;
  font-family: Merriweather, serif;
}

#map {
  width: 100%;
  height: 240px;
  display: none;
}

textarea:focus, input:focus, select:focus {
  outline: none;
}

textarea {
  line-height: 1.5em;
}

.input-phone, .country-select {
  border: none;
  padding: 3px;
  font-size: 24px;
}

.number-group {
  gap: 5px;
  display: flex;
}

.number-group select {
  width: 86px;
}

h1, h2, h3, button, .button {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 700;
}

h2 {
  color: var(--secondary-color);
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 3rem;
  line-height: 1;
}

h3 {
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400;
}

.link-secondary {
  color: #666;
  margin-top: 2rem;
  display: block;
}

.overlay {
  z-index: 1000;
  background-color: #ffffffe6;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.overlay.show {
  display: flex;
}

.overlay .loading, .overlay .success, .overlay .finish {
  display: none;
}

.overlay.loading .loading, .overlay.success .success, .overlay.finish .finish {
  display: block;
}

.overlay .success {
  text-align: center;
  margin-top: 0;
  font-size: 60px;
  display: none;
}

.overlay .success span {
  width: 230px;
  font-family: Poppins;
  font-size: 24px;
  display: block;
}

.overlay .finish {
  text-align: center;
}

.overlay .finish span {
  width: 230px;
  font-family: Poppins;
  font-size: 24px;
  display: block;
}

.carousel-container {
  background-color: #fff;
  border-radius: 7px;
  min-width: 320px;
  max-width: 540px;
  margin: auto;
  padding: 30px;
  position: relative;
  overflow: hidden;
  box-shadow: 1px 1px #00000024;
}

.stepper {
  border-top: 1px solid #f2efee;
  justify-content: center;
  margin-top: 0;
  margin-bottom: 5px;
  padding-top: 2rem;
  display: flex;
}

.step {
  text-align: center;
  cursor: pointer;
  background: #ddd;
  border-radius: 3px;
  width: 10px;
  height: 4px;
  margin: 0 5px;
  transition: background-color .3s, color .3s;
}

.step.tns-nav-active {
  background-color: var(--primary-color);
}

#multiStepForm {
  height: auto;
  margin-bottom: 30px;
  position: relative;
}

.form-step.active {
  opacity: 1;
  left: 0;
}

.form-step.out {
  opacity: 0;
  left: -100%;
}

.tns-item {
  overflow: hidden !important;
}

button, .button {
  background-color: var(--primary-color);
  color: #fff;
  cursor: pointer;
  letter-spacing: .2px;
  border: none;
  border-radius: 5px;
  margin-top: 3rem;
  padding: 10px 15px;
  font-size: 1.2rem;
  text-decoration: none;
  transition: background-color .3s;
  display: inline-block;
}

#send-owner-message {
  align-items: center;
  display: flex;
}

button:disabled, button[disabled] {
  background-color: var(--background-color);
  color: #999;
}

button svg {
  margin-right: 10px;
}

.btn-secondary {
  background-color: var(--background-color);
  color: #333;
  margin-left: 5px;
}

button:hover {
  background-color: #cc3a00;
}

@media (width <= 580px) {
  .carousel-container {
    margin: 20px;
  }
}

#code-container {
  flex-wrap: wrap;
  justify-content: center;
  gap: 5px;
  display: inline-flex;
}

#code-container .code-input {
  text-align: center;
  width: calc(16.6667% - 5px);
}

.input-text {
  border: 2px solid var(--background-color);
  background: var(--background-color);
  border-radius: 5px;
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 20px;
  display: block;
}

input:-internal-autofill-selected {
  border: 2px solid var(--background-color);
  background: var(--background-color);
  border-radius: 5px;
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 20px;
  display: block;
}

.code-input:focus {
  background-color: var(--primary-color);
  color: #fff;
  border-color: var(--primary-color);
  outline: none;
}

/*# sourceMappingURL=index.67d391c2.css.map */
