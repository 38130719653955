:root {
    --primary-color: #FF4A01;
    --secondary-color: #1B1F6B;
    --background-color: #f2efee;
    --main-title-font: 'Verdana', sans-serif;
    --logo-src-url: 'https://www.logo.nl/logo.svg';
}

* {
    box-sizing: border-box;
}

a {
    color: var(--primary-color);
    text-decoration: none;
}

html, body {
    font-family: "Merriweather", serif;
    margin: 0;
    padding: 0;
    color: #333;
    font-size: 14px;
    line-height: 1.6;
    background-color: var(--background-color);
}

header {
    max-width: 320px;
    margin: 35px auto 30px;
    overflow: hidden;
    text-align: center;
}

#recaptcha-container {
    margin-top: 20px;
}

#finders-message {
    display: none;
    padding: 20px;
    background-color: #f2efee;
    font-style: italic;
}

input, textarea, select {
    font-family: "Merriweather", serif;
    outline: none;
}

#map {
    display: none;
    height: 240px;
    width: 100%;
}

textarea:focus, input:focus, select:focus {
    outline: none;
}

textarea {
    line-height: 1.5em;
}

.input-phone, .country-select {
    font-size: 24px;
    border: none;
    padding: 3px;
}

.number-group {
    display: flex;
    gap: 5px;
}

.number-group select {
    width: 86px;
}

h1, h2, h3, button, .button {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: normal;
}

h2 {
    margin-top: 0;
    font-size: 3rem;
    line-height: 1;
    margin-bottom: 16px;
    color: var(--secondary-color);
}

h3 {
    font-weight: 400;
    margin-bottom: 0;
    margin-top: 0;
    text-transform: uppercase;
    font-size: 16px;
}

.link-secondary {
    color: #666;
    display: block;
    margin-top: 2rem;
}

.overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9); 
    display: none; 
    justify-content: center;
    align-items: center;
    z-index: 1000; 
}

.overlay.show {
    display: flex;
}

.overlay .loading,
.overlay .success,
.overlay .finish { display: none; }

.overlay.loading .loading,
.overlay.success .success,
.overlay.finish .finish { display: block; }

.overlay .success { 
    font-size: 60px;
    display: none;
    text-align: center;
    margin-top: 0;
}

.overlay .success span { 
    font-size: 24px;
    display: block;
    width: 230px;
    font-family: 'Poppins';
}

.overlay .finish {
    text-align: center;
}

.overlay .finish span { 
    font-size: 24px;
    display: block;
    width: 230px;
    font-family: 'Poppins';
}

.carousel-container {
    margin: auto;
    padding: 30px;
    background-color: #fff;
    border-radius: 7px;
    min-width: 320px;
    max-width: 540px;
    box-shadow: 1px 1px rgba(0, 0, 0, 0.14);
    overflow: hidden;
    position: relative;
}

.stepper {
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
    margin-top: 0px;
    border-top: 1px solid #f2efee;
    padding-top: 2rem;
}

.step {
    width: 10px;
    height: 4px;
    background: #ddd;
    border-radius: 3px;
    text-align: center;
    cursor: pointer;
    margin: 0 5px;
    transition: background-color 0.3s, color 0.3s;
}

.step.tns-nav-active {
    background-color: var(--primary-color);
}

#multiStepForm {
    position: relative;
    height: auto;
    margin-bottom: 30px;
}

.form-step.active {
    left: 0;
    opacity: 1;
}

.form-step.out {
    left: -100%;
    opacity: 0;
}

.tns-item {
    overflow: hidden !important;
}

button, .button {
    margin-top: 3rem;
    padding: 10px 15px;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 1.2rem;
    letter-spacing: 0.2px;
    text-decoration: none;
    display: inline-block;
}

#send-owner-message {
    display: flex;
    align-items: center;
}

button:disabled,
button[disabled]{
  background-color: var(--background-color);
  color: #999;
}

button svg {
    margin-right: 10px; /* Adjust as needed */
}

.btn-secondary {
    background-color: var(--background-color);
    color: #333;
    margin-left: 5px;
}

button:hover {
    background-color: #cc3a00;
}

@media (max-width: 580px) {
    .carousel-container {
        margin: 20px;
    }
}

#code-container {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 5px;
}

#code-container .code-input {
    text-align: center;
    width: calc(100% / 6 - 5px);
}

.input-text, input:-internal-autofill-selected  {
    padding: 10px;
    font-size: 20px;
    border: 2px solid var(--background-color);
    background: var(--background-color);
    border-radius: 5px;
    margin-bottom: 10px;
    display: block;
    width: 100%;
}

.code-input:focus {
    background-color: var(--primary-color);
    color: #fff;
    outline: none;
    border-color: var(--primary-color);
}
